import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  IEventFilterProps,
  IActiveFilterState,
  IDateFiltered,
  ICategoryFiltered,
  IPlaceFiltered,
  IFestivalFiltered,
  IDeleteRouteFilter,
} from './types'
import {
  getCategories,
  getFestivalsV2,
  getPlaces,
} from '@/components/widgets/events/services'
import {
  dateList,
  queryDatesConvert,
} from '@/components/widgets/events/costants'

import { functions, useApp, useWidth } from '@wap-client/core'
import Icon from '@/components/base/icon'
import { useRouter } from 'next/router'
import { usePathname } from 'next/navigation'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import { tr } from 'date-fns/locale/tr'
import { enGB } from 'date-fns/locale/en-GB'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import Button from '@/components/base/button/Button'
import { queryService } from '@wap-client/services'

const EventFilterv2: React.FC<IEventFilterProps> = ({
  isPanel,
  setIsPanel,
  festivalID,
  isArchivePage = false,
}) => {
  const app = useApp()
  const width = useWidth()
  const [isActiveButton, setIsActiveButton] = useState<IActiveFilterState>('')

  const [categories, setCategories] = useState<Array<ICategoryFiltered>>([])
  const [places, setPlaces] = useState<Array<IPlaceFiltered>>([])
  const [festivals, setFestivals] = useState<Array<IFestivalFiltered>>([])
  const [searchTitle, setSearchTitle] = useState<string>('')
  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null])
  const [activeFestival, setActiveFestival] =
    useState<IFestivalFiltered | null>(null)
  const [followScroll, setFollowScroll] = useState<boolean>(false)
  const [pickYear, setPickYear] = useState<string>('')
  const [allYears, setAllYears] = useState<string[]>([])

  const router = useRouter()
  const pathname = usePathname()
  const currentDate = useMemo(() => new Date(), [])

  useEffect(() => {
    if (router.query?.festivalId) {
      getPlaces(app, {
        name: 'festivalId',
        value: router.query.festivalId as string,
      }).then((res) => res && setPlaces(res))
    } else if (festivalID) {
      getPlaces(app, {
        name: 'festivalId',
        value: festivalID,
      }).then((res) => res && setPlaces(res))
    } else {
      setPlaces([])
    }

    if (
      router.query?.startDate &&
      router.query?.endDate &&
      router.query.dates === 'picker'
    ) {
      const start = new Date(router.query.startDate as string)
      const end = new Date(router.query.endDate as string)
      setDateRange([start, end])
    }

    if (
      isArchivePage &&
      router.query?.dates === 'pickYear' &&
      router.query?.startDate
    ) {
      const convertYear = String(moment(router.query.startDate).get('year'))
      setPickYear(convertYear)
    } else if (isArchivePage && !router.query?.dates) {
      setPickYear(
        new Date() > new Date(2025, 3, 16)
          ? String(new Date().getFullYear())
          : String(new Date().getFullYear() - 1)
      )
    }
    getCategories(app).then((res) => res && setCategories(res))
    getFestivalsV2(app).then((res) => res && setFestivals(res))
  }, [app, router.query, festivalID, isArchivePage])

  useEffect(() => {
    const activeFind = festivals.find((festival) => {
      if (festival?.baslangicTarihi && festival?.bitisTarihi) {
        const startDate = new Date(festival?.baslangicTarihi)
        const endDate = new Date(festival?.bitisTarihi)
        if (startDate <= currentDate && currentDate <= endDate) {
          return true
        }
      }
    })

    activeFind && setActiveFestival(activeFind)
  }, [festivals, currentDate])

  useEffect(() => {
    function hanleBlurCloseFilter(e: any) {
      const { target } = e
      if (!target.className.includes('filter-button')) {
        setIsActiveButton('')
      }
    }

    if (isActiveButton) {
      document.addEventListener('click', hanleBlurCloseFilter)
      setFollowScroll(true)
    } else {
      document.removeEventListener('click', hanleBlurCloseFilter)
      setFollowScroll(false)
    }

    return () => {
      document.removeEventListener('click', hanleBlurCloseFilter)
    }
  }, [isActiveButton])

  useEffect(() => {
    function handleFilterVisibility() {
      if (followScroll) {
        setIsActiveButton('')
      }
    }

    if (followScroll) {
      document.addEventListener('scroll', handleFilterVisibility)
    } else {
      document.removeEventListener('scroll', handleFilterVisibility)
    }

    return () => {
      document.removeEventListener('scroll', handleFilterVisibility)
    }
  }, [followScroll])

  const getAllYears = useCallback(async () => {
    try {
      const response = await queryService.run<
        Array<{ id: string; bitisTarihi: string }>
      >(app.environment, {
        name: 'kty-etkinlikler-get-etkinlik-yillari-veri-sorgusu',
        language: app.language,
        query: '',
      })

      if (response && response?.data.length > 0) {
        const getYearsReduce = response.data.reduce(
          (years: string[], event) => {
            const convertDate = String(moment(event?.bitisTarihi).get('year'))
            if (!isNaN(Number(convertDate)) && !years.includes(convertDate)) {
              years.push(convertDate)
            }

            return years
          },
          []
        )
        setAllYears(getYearsReduce)
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    getAllYears()
  }, [getAllYears])

  const handleActiveFilter = (buttonName: IActiveFilterState) => {
    if (isActiveButton !== buttonName) {
      setIsActiveButton(buttonName)
    } else setIsActiveButton('')
  }

  const hanlePushRouteFilter = (
    filterName: IActiveFilterState,
    filter: IDateFiltered,
    clearPlace?: boolean
  ) => {
    const params = new URLSearchParams(window.location.search)

    if (filterName === 'dates') {
      dateRange[0] !== null && setDateRange([null, null])
      if (filter.startDate && filter.endDate && filter.id) {
        if (filter.id === '4') {
          params.set('dates', queryDatesConvert(filter.id))
          params.set('endDate', filter?.endDate)
          params.delete('startDate')
        } else {
          const check =
            moment.utc(filter.startDate).startOf('day') >
            moment.utc(new Date()).startOf('day')
          params.set('dates', queryDatesConvert(filter.id))
          params.set(
            'startDate',
            moment(check ? filter.startDate : new Date())
              .startOf('day')
              .format()
              .split('+')[0]
          )
          params.set(
            'endDate',
            moment(filter.endDate).endOf('day').format().split('+')[0]
          )
        }
      }
    }
    if (filterName === 'categories' && filter.id) {
      params.set('categoryId', filter.id)
    }
    if (filterName === 'festivals' && filter.id) {
      params.set('festivalId', filter.id)
      if (clearPlace) {
        params.delete('placeId')
      }
    }
    if (filterName === 'places' && filter.id) {
      params.set('placeId', filter.id)
    }
    if (filterName === 'date-picker') {
      if (filter.startDate && filter.endDate && filter.id) {
        params.set('dates', queryDatesConvert(filter.id))
        params.set('startDate', filter.startDate)
        params.set('endDate', filter.endDate)
      } else {
        params.delete('dates')
        params.delete('startDate')
        params.delete('endDate')
      }
    }
    if (filterName === 'search-keyword') {
      if (filter.keyword !== '' && filter.keyword) {
        params.set('title', filter.keyword)
      } else {
        params.delete('title')
      }
    }

    if (filterName === 'isFree') {
      const isFree = filter.isFree?.toString()
      if (isFree) {
        params.set('isFree', isFree)
      } else {
        params.delete('isFree')
      }
    }

    if (filterName === 'pickYear') {
      params.set('dates', filterName)
      params.delete('startDate')
      params.delete('endDate')
      params.delete('oldYearStartDate')
      params.delete('oldYearEndDate')

      if (filter.startDate && filter.endDate) {
        params.set('startDate', filter.startDate)
        params.set('endDate', filter.endDate)
      }

      if (filter.oldYearStartDate && filter.oldYearEndDate) {
        params.set('oldYearStartDate', filter.oldYearStartDate)
        params.set('oldYearEndDate', filter.oldYearEndDate)
      }
    }

    router.replace(
      pathname + `${params.toString() && '?' + params.toString()}`,
      undefined,
      {
        scroll: false,
      }
    )

    setIsActiveButton('')
  }

  const handleDeleteFilter = (filterName: IDeleteRouteFilter) => {
    const params = new URLSearchParams(window.location.search)

    switch (filterName) {
      case 'festivalId':
        params.delete('festivalId')
        params.delete('placeId')
        break
      case 'dates' || 'pickYear':
        params.delete('dates')
        params.delete('startDate')
        params.delete('endDate')
        break
      case 'pickYear':
        const endYear = moment.utc(new Date()).toISOString()
        params.set('dates', 'pickYear')
        params.delete('startDate')
        params.delete('oldYearEndDate')
        params.delete('oldYearStartDate')
        params.set('endDate', endYear)
        setPickYear('')
        break
      case 'isFree':
        params.delete('isFree')
        break
      default:
        params.delete(filterName)
        break
    }

    router.push(pathname + '?' + params.toString(), undefined, {
      scroll: false,
    })
  }

  const renderDateButton = () => {
    const params = new URLSearchParams(window?.location?.search)
    const paramDates = params.get('dates')

    function renderSelectedFilterClear(dateId?: string) {
      if (paramDates === queryDatesConvert(dateId ?? ''))
        return (
          <Icon
            name="close"
            className="delete-filter-icon"
            size="small"
            onClick={() => {
              handleDeleteFilter('dates')
              setIsActiveButton('')
            }}
          />
        )
    }

    const renderDateTitle = (dateId?: string) => {
      switch (dateId) {
        case '0':
          return app.settings.translations['dateToday']
        case '1':
          return app.settings.translations['dateTomorrow']
        case '2':
          return app.settings.translations['dateWeek']
        case '3':
          return app.settings.translations['dateMonth']
        case '4':
          return app.settings.translations['dateArchive']
        default:
          return ''
      }
    }

    return (
      <div className="filter-button-layout">
        <button
          className={functions.classnames(
            'filter-button',
            router.query?.dates && router.query.dates !== 'picker' && 'selected'
          )}
          onClick={() => handleActiveFilter('dates')}
        >
          <span className="filter-button-title">
            {app.settings.translations[`filter-dates`]}
          </span>
          <Icon
            name={isActiveButton === 'dates' ? 'chev-down' : 'chev-down'}
            className={functions.classnames(
              'filter-button-arrow-icon',
              isActiveButton === 'dates' && 'active'
            )}
            size="small"
          />
        </button>
        <ul
          className={functions.classnames(
            'filter-button-list',
            isActiveButton === 'dates' && 'visible'
          )}
        >
          {dateList.map((date, index) => (
            <li key={index} className="filter-button-list-item">
              <button
                onClick={() => hanlePushRouteFilter('dates', date)}
                className={functions.classnames(
                  paramDates === queryDatesConvert(date?.id ?? '') &&
                    'already-active'
                )}
              >
                <span
                  className={functions.classnames(
                    paramDates === queryDatesConvert(date?.id ?? '') &&
                      'selected-filter-name'
                  )}
                >
                  {renderDateTitle(date.id)}
                </span>
              </button>
              {renderSelectedFilterClear(date?.id)}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const renderPriceButton = () => {
    const params = new URLSearchParams(window?.location?.search)
    const paramIsFree = params.get('isFree')

    function renderSelectedFilterClear(isFree?: string) {
      if (paramIsFree === isFree)
        return (
          <Icon
            name="close"
            className="delete-filter-icon"
            size="small"
            onClick={() => {
              handleDeleteFilter('isFree')
              setIsActiveButton('')
            }}
          />
        )
    }

    return (
      <div className="filter-button-layout">
        <button
          className={functions.classnames(
            'filter-button',
            router.query?.isFree && 'selected'
          )}
          onClick={() => handleActiveFilter('isFree')}
        >
          <span className="filter-button-title">
            {app.settings.translations['fee']}
          </span>
          <Icon
            name={isActiveButton === 'isFree' ? 'chev-down' : 'chev-down'}
            className={functions.classnames(
              'filter-button-arrow-icon',
              isActiveButton === 'isFree' && 'active'
            )}
            size="small"
          />
        </button>
        <ul
          className={functions.classnames(
            'filter-button-list',
            isActiveButton === 'isFree' && 'visible'
          )}
        >
          <li className="filter-button-list-item">
            <button
              onClick={() => hanlePushRouteFilter('isFree', { isFree: true })}
              className={functions.classnames(
                paramIsFree === 'true' && 'already-active'
              )}
            >
              <span
                className={functions.classnames(
                  paramIsFree === 'true' && 'selected-filter-name'
                )}
              >
                {app.settings.translations['free']}
              </span>
            </button>
            {renderSelectedFilterClear('true')}
          </li>
          <li className="filter-button-list-item">
            <button
              onClick={() => hanlePushRouteFilter('isFree', { isFree: false })}
              className={functions.classnames(
                paramIsFree === 'false' && 'already-active'
              )}
            >
              <span
                className={functions.classnames(
                  paramIsFree === 'false' && 'selected-filter-name'
                )}
              >
                {app.settings.translations['paid']}
              </span>
            </button>
            {renderSelectedFilterClear('false')}
          </li>
        </ul>
      </div>
    )
  }

  const renderCategoriesButton = () => {
    const params = new URLSearchParams(window?.location?.search)
    const paramCategoryId = params.get('categoryId')

    function renderSelectedFilterClear(categoryId?: string) {
      if (paramCategoryId === categoryId)
        return (
          <Icon
            name="close"
            className="delete-filter-icon"
            size="small"
            onClick={() => {
              handleDeleteFilter('categoryId')
              setIsActiveButton('')
            }}
          />
        )
    }

    return (
      <div className="filter-button-layout">
        <button
          className={functions.classnames(
            'filter-button',
            router.query?.categoryId && 'selected'
          )}
          onClick={() => handleActiveFilter('categories')}
        >
          <span className="filter-button-title">
            {app.settings.translations[`filter-categories`]}
          </span>
          <Icon
            name={isActiveButton === 'categories' ? 'chev-down' : 'chev-down'}
            className={functions.classnames(
              'filter-button-arrow-icon',
              isActiveButton === 'categories' && 'active'
            )}
            size="small"
          />
        </button>
        <ul
          className={functions.classnames(
            'filter-button-list',
            isActiveButton === 'categories' && 'visible'
          )}
        >
          {categories.map((category, index) => (
            <li key={index} className="filter-button-list-item">
              <button
                onClick={() => hanlePushRouteFilter('categories', category)}
                className={functions.classnames(
                  paramCategoryId === category?.id && 'already-active'
                )}
              >
                <span
                  className={functions.classnames(
                    paramCategoryId === category?.id && 'selected-filter-name'
                  )}
                >
                  {category.baslik}
                </span>
              </button>
              {renderSelectedFilterClear(category?.id)}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const renderPlacesButton = () => {
    const params = new URLSearchParams(window?.location?.search)
    const paramPlaceId = params.get('placeId')

    function renderSelectedFilterClear(placeId?: string) {
      if (paramPlaceId === placeId)
        return (
          <Icon
            name="close"
            className="delete-filter-icon"
            size="small"
            onClick={() => {
              handleDeleteFilter('placeId')
              setIsActiveButton('')
            }}
          />
        )
    }

    const renderFestivalForPlace = () => {
      if (!router.query?.festivalId) {
        return (
          <li className="festival-first filter-button-list-item">
            <Icon
              name="info"
              className="filter-button-list-icon"
              size="medium"
            />
            <span className="info-text filter-button-list-item-text">
              {app.settings.translations['festivalFirst']}
            </span>
          </li>
        )
      } else if (places.length === 0) {
        return (
          <li className="festival-first filter-button-list-item">
            <Icon
              name="help"
              className="filter-button-list-icon"
              size="medium"
            />
            <span className="info-text filter-button-list-item-text">
              {app.settings.translations['notFoundPlace']}
            </span>
          </li>
        )
      } else {
        return places.map((place, index) => (
          <li key={index} className="filter-button-list-item">
            <button
              onClick={() => hanlePushRouteFilter('places', place)}
              className={functions.classnames(
                paramPlaceId === place?.id && 'already-active'
              )}
            >
              <span
                className={functions.classnames(
                  paramPlaceId === place?.id && 'selected-filter-name'
                )}
              >
                {place.baslik}
              </span>
            </button>
            {renderSelectedFilterClear(place?.id)}
          </li>
        ))
      }
    }

    return (
      <div className="filter-button-layout">
        <button
          className={functions.classnames(
            'filter-button',
            router.query?.placeId && 'selected'
          )}
          onClick={() => handleActiveFilter('places')}
        >
          <span className="filter-button-title">
            {app.settings.translations[`filter-places`]}
          </span>
          <Icon
            name={isActiveButton === 'places' ? 'chev-down' : 'chev-down'}
            className={functions.classnames(
              'filter-button-arrow-icon',
              isActiveButton === 'places' && 'active'
            )}
            size="small"
          />
        </button>
        <ul
          className={functions.classnames(
            'filter-button-list',
            isActiveButton === 'places' && 'visible'
          )}
        >
          {renderFestivalForPlace()}
        </ul>
      </div>
    )
  }

  const renderFestivalsButton = () => {
    const params = new URLSearchParams(window?.location?.search)
    const paramFestivalId = params.get('festivalId')

    function renderSelectedFilterClear(festivalId?: string) {
      if (paramFestivalId === festivalId)
        return (
          <Icon
            name="close"
            className="delete-filter-icon"
            size="small"
            onClick={() => {
              handleDeleteFilter('festivalId')
              setIsActiveButton('')
            }}
          />
        )
    }

    return (
      <div className="filter-button-layout">
        <button
          className={functions.classnames(
            'filter-button',
            router.query?.festivalId && 'selected',
            festivalID && 'selected'
          )}
          onClick={() => handleActiveFilter('festivals')}
        >
          <span className="filter-button-title">
            {app.settings.translations[`filter-festivals`]}
          </span>
          <Icon
            name={isActiveButton === 'festivals' ? 'chev-down' : 'chev-down'}
            className={functions.classnames(
              'filter-button-arrow-icon',
              isActiveButton === 'festivals' && 'active'
            )}
            size="small"
          />
        </button>
        <ul
          className={functions.classnames(
            'filter-button-list',
            isActiveButton === 'festivals' && 'visible'
          )}
        >
          {activeFestival && (
            <li className="filter-button-list-item">
              <button
                onClick={() =>
                  hanlePushRouteFilter('festivals', activeFestival, true)
                }
              >
                <span
                  className={functions.classnames(
                    paramFestivalId === activeFestival?.id &&
                      'selected-filter-name'
                  )}
                >
                  {activeFestival?.baslik}
                </span>
              </button>
              <span className="active-festival">
                {app.settings.translations['active']}
              </span>
              {paramFestivalId === activeFestival?.id && (
                <Icon
                  name="close"
                  className="delete-filter-icon"
                  size="small"
                  onClick={() => handleDeleteFilter('festivalId')}
                />
              )}
            </li>
          )}

          {festivals.map((festival, index) => {
            if (activeFestival?.id !== festival?.id) {
              return (
                <li key={index} className="filter-button-list-item">
                  <button
                    onClick={() =>
                      hanlePushRouteFilter('festivals', festival, true)
                    }
                    className={functions.classnames(
                      paramFestivalId === festival?.id && 'already-active'
                    )}
                  >
                    <span
                      className={functions.classnames(
                        paramFestivalId === festival?.id &&
                          'selected-filter-name'
                      )}
                    >
                      {festival.baslik}
                    </span>
                  </button>
                  {renderSelectedFilterClear(festival?.id)}
                </li>
              )
            }
          })}
        </ul>
      </div>
    )
  }

  const renderDatePicker = () => {
    const langShortCode = app.language.substring(0, 2)
    registerLocale(
      langShortCode === 'tr' ? 'tr' : 'enGB',
      langShortCode === 'tr' ? tr : enGB
    )

    const handleChanceDate = (dates: any) => {
      if (dates[0] && dates[1]) {
        const [start, end] = dates

        if (start && end) {
          const isoDateStart = moment(start).format().split('+')[0]
          const isoDateEnd = moment(end).endOf('day').format().split('+')[0]
          hanlePushRouteFilter('date-picker', {
            id: '999',
            startDate: isoDateStart,
            endDate: isoDateEnd,
          })
        }
      } else if (!dates[0] && !dates[1]) {
        hanlePushRouteFilter('date-picker', {})
      }

      setDateRange(dates)
    }

    return (
      <div
        className={functions.classnames(
          'filter-calendar',
          dateRange[0] && dateRange[1] && 'selected'
        )}
      >
        <Icon name="calendar" size="medium" />
        <DatePicker
          closeOnScroll={(e) => e.target === document}
          onFocus={() => setIsActiveButton('date-picker')}
          onChange={(dates: any) => handleChanceDate(dates)}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          selectsRange={true}
          minDate={new Date()}
          className="date-picker"
          isClearable={true}
          placeholderText={app.settings.translations['searchDate']}
          locale={langShortCode === 'tr' ? 'tr' : 'enGB'}
          dateFormat="dd/MM/yyyy"
        ></DatePicker>
      </div>
    )
  }

  const renderDatePickerArchivePage = () => {
    const langShortCode = app.language.substring(0, 2)
    registerLocale(
      langShortCode === 'tr' ? 'tr' : 'enGB',
      langShortCode === 'tr' ? tr : enGB
    )

    const handleChanceDate = (date: string) => {
      const nowYear = moment.utc().year().toString()
      const startYear = moment.utc().year(+date).startOf('year').toISOString()
      const endYear =
        String(new Date().getFullYear()) !== date
          ? moment.utc().year(+date).endOf('year').toISOString()
          : moment.utc(new Date()).toISOString()

      if (nowYear === date) {
        hanlePushRouteFilter(
          'pickYear',
          { startDate: startYear, endDate: endYear },
          false
        )
      } else {
        hanlePushRouteFilter(
          'pickYear',
          { oldYearStartDate: startYear, oldYearEndDate: endYear },
          false
        )
      }

      setPickYear(date)
    }

    return (
      <div className="filter-buttons">
        <div className="filter-button-layout">
          <button
            className={functions.classnames(
              'filter-button',
              pickYear !== '' && 'selected'
            )}
            onClick={() => handleActiveFilter('pickYear')}
          >
            <span
              className={functions.classnames(
                'filter-button-title',
                pickYear !== '' && 'select-title'
              )}
            >
              {pickYear || app.settings.translations['pickYear']}
            </span>
            <Icon
              name={isActiveButton === 'pickYear' ? 'chev-down' : 'chev-down'}
              className={functions.classnames(
                'filter-button-arrow-icon',
                isActiveButton === 'pickYear' && 'active'
              )}
              size="small"
            />
          </button>
          <ul
            className={functions.classnames(
              'filter-button-list',
              isActiveButton === 'pickYear' && 'visible'
            )}
            style={{ width: '100%' }}
          >
            {allYears.map((year, index) => {
              return (
                <li className="filter-button-list-item" key={index}>
                  <button
                    onClick={() => handleChanceDate(year)}
                    className={functions.classnames(
                      pickYear === year && 'already-active'
                    )}
                  >
                    <span
                      className={functions.classnames(
                        pickYear === year && 'selected-filter-name'
                      )}
                    >
                      {year}
                    </span>
                  </button>
                  {pickYear === year && (
                    <Icon
                      name="close"
                      className="delete-filter-icon"
                      size="small"
                      onClick={() => handleDeleteFilter('pickYear')}
                    />
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  const renderKewordSearch = () => {
    const handleChance = (value: string) => {
      hanlePushRouteFilter('search-keyword', { keyword: value })
      setSearchTitle(value)
    }

    const handleReset = () => {
      const params = new URLSearchParams(window.location.search)

      if (params.get('title')) {
        hanlePushRouteFilter('search-keyword', { keyword: '' })
      }
      setSearchTitle('')
    }

    return (
      <div
        className={functions.classnames(
          'keyword-search',
          searchTitle.length > 0 && 'enter-value'
        )}
      >
        <input
          className={functions.classnames(
            isActiveButton === 'search-keyword' && 'focused',
            'keyword-search-input',
            width < 1024 && 'mobile-v'
          )}
          type="text"
          placeholder={app.settings.translations['searchEvent']}
          value={searchTitle}
          onChange={(e) => handleChance(e.target.value)}
          onFocus={() => handleActiveFilter('search-keyword')}
          onBlur={() => handleActiveFilter('')}
        ></input>
        <Icon className="search-icon" name="search-button" size="medium" />
        <button
          className={functions.classnames(
            'clear-search-button',
            searchTitle.length > 0 && 'active'
          )}
          onClick={() => handleReset()}
        >
          <Icon name="close" size="small" />
        </button>
      </div>
    )
  }

  const renderSelectedValues = () => {
    const handlerDeleteFilter = (filterName: IDeleteRouteFilter) => {
      handleDeleteFilter(filterName)
    }

    const renderSelectedButtons = () => {
      return Object.entries(router.query).map(([key, value], index) => {
        if (key === 'categoryId') {
          const filter = categories.find((category) => category.id === value)
          if (filter) {
            return (
              <div
                className={functions.classnames('selected-item', 'category')}
                key={index}
              >
                <span>{filter?.baslik}</span>
                <button
                  className="delete-item-button"
                  onClick={() => handlerDeleteFilter('categoryId')}
                >
                  <Icon name="close" size="small" />
                </button>
              </div>
            )
          }
        } else if (key === 'placeId') {
          const filter = places.find((place) => place.id === value)
          if (filter) {
            return (
              <div
                className={functions.classnames('selected-item', 'category')}
                key={index}
              >
                <span>{filter?.baslik}</span>
                <button
                  className="delete-item-button"
                  onClick={() => handlerDeleteFilter('placeId')}
                >
                  <Icon name="close" size="small" />
                </button>
              </div>
            )
          }
        } else if (key === 'festivalId') {
          const filter = festivals.find((festival) => festival.id === value)

          if (filter) {
            return (
              <div
                className={functions.classnames('selected-item', 'category')}
                key={index}
              >
                <span>{filter?.baslik}</span>
                <button
                  className="delete-item-button"
                  onClick={() => handlerDeleteFilter('festivalId')}
                >
                  <Icon name="close" size="small" />
                </button>
              </div>
            )
          }
        } else if (festivalID) {
          const filter = festivals.find(
            (festival) => festival.id === festivalID
          )

          if (filter) {
            return (
              <div
                className={functions.classnames('selected-item', 'category')}
                key={index}
              >
                <span>{filter?.baslik}</span>
                <button
                  className="delete-item-button"
                  onClick={() => handlerDeleteFilter('festivalId')}
                >
                  <Icon name="close" size="small" />
                </button>
              </div>
            )
          }
        } else if (key === 'isFree') {
          const filter = router.query?.isFree
          if (filter) {
            return (
              <div
                className={functions.classnames('selected-item', 'category')}
                key={index}
              >
                <span>
                  {filter === 'true'
                    ? app.settings.translations['free']
                    : app.settings.translations['paid']}
                </span>
                <button
                  className="delete-item-button"
                  onClick={() => handlerDeleteFilter('isFree')}
                >
                  <Icon name="close" size="small" />
                </button>
              </div>
            )
          }
        } else if (key === 'dates' && router.query?.dates !== 'date-picker') {
          const getDaysId = () => {
            switch (value) {
              case 'today':
                return '0'
              case 'tomorrow':
                return '1'
              case 'week':
                return '2'
              case 'month':
                return '3'
              case 'archive':
                return '4'
              default:
                return ''
            }
          }

          const renderDateTitle = (dateId?: string) => {
            switch (dateId) {
              case '0':
                return app.settings.translations['dateToday']
              case '1':
                return app.settings.translations['dateTomorrow']
              case '2':
                return app.settings.translations['dateWeek']
              case '3':
                return app.settings.translations['dateMonth']
              case '4':
                return app.settings.translations['dateArchive']
              default:
                return ''
            }
          }
          if (getDaysId() !== '') {
            const date = dateList.find((date) => date.id === getDaysId())

            return (
              <div
                className={functions.classnames('selected-item', 'category')}
                key={index}
              >
                <span>{renderDateTitle(date?.id)}</span>
                <button
                  className="delete-item-button"
                  onClick={() => handlerDeleteFilter('dates')}
                >
                  <Icon name="close" size="small" />
                </button>
              </div>
            )
          }
        }
      })
    }

    return (
      <div className="event-filter-selected">{renderSelectedButtons()}</div>
    )
  }

  return (
    <div className="event-filter">
      <div
        className={functions.classnames(
          'event-filter-filters',
          isArchivePage && 'archive-filters'
        )}
      >
        {!isArchivePage && width > 1025 ? (
          <div className="filter-buttons">
            {renderDateButton()}
            {renderCategoriesButton()}
            {renderPriceButton()}
            {renderFestivalsButton()}
            {renderPlacesButton()}
          </div>
        ) : null}
        <div className="filter-search">
          {width > 1025 && !isArchivePage
            ? renderDatePicker()
            : width > 1025 && isArchivePage
            ? renderDatePickerArchivePage()
            : null}
          {renderKewordSearch()}
          {width < 1025 && (
            <div
              className={functions.classnames(
                'filter-panel',
                !isArchivePage && Object.keys(router.query).length > 1
                  ? 'filter-panel--filtered'
                  : isArchivePage && !router.query?.dates
                  ? 'filter-panel--filtered'
                  : isArchivePage &&
                    router.query?.startDate &&
                    router.query?.endDate &&
                    'filter-panel--filtered'
              )}
            >
              <Button onClick={() => setIsPanel(!isPanel)}>
                {app.settings.translations['filter']}
              </Button>
            </div>
          )}
        </div>
      </div>
      {Object.keys(router.query).length > 0 &&
        !isArchivePage &&
        renderSelectedValues()}
    </div>
  )
}

export default EventFilterv2
