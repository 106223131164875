import React, { useEffect, useRef } from 'react'
import { EVENTS_MAPPING } from '../costants'
import { useApp } from '@wap-client/core'
import { queryService } from '@wap-client/services'
import { IEvent } from '../event-card/types'
import useSWR, { mutate } from 'swr'
import { Column, Row } from '@/components/base/gridview'
import EventCard from '../event-card'
import Button from '@/components/base/button/Button'
import EventCardSkeleton from '../skeletons/event-card/EventCardSkeleton'
import Anchor from '@/components/base/anchor/Anchor'
import Icon from '@/components/base/icon'
import { useInView } from 'framer-motion'
import { useRouter } from 'next/router'
import { usePathname } from 'next/navigation'
import moment from 'moment'

type Props = {
  page: number
  loadMore: () => void
  isLastPage: boolean
  isHomePage: boolean
  festivalID: string
  isArchivePage: boolean
  isGlobalEvents: boolean
}

const PAGE_SIZE = 8

const fetcher = async (
  app: any,
  page: number,
  isArchivePage: boolean,
  isGlobalEvents: boolean
) => {
  const query = new URLSearchParams(window.location.search)

  query.set('pageIndex', `${page - 1}`)
  query.set('pageSize', PAGE_SIZE.toString())

  if (isArchivePage && query.get('dates') !== 'pickYear') {
    const nowYear =
      new Date() > new Date(2025, 3, 16)
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1
    const startYear = moment.utc().year(nowYear).startOf('year').toISOString()
    const endYear = moment.utc(new Date()).toISOString()

    query.set('dates', 'pickYear')
    query.set('startDate', startYear)
    query.set('endDate', endYear)
  }

  if (!isArchivePage && !query.get('startDate')) {
    const nowYear = new Date().getFullYear()
    const startYear = moment.utc().year(nowYear).startOf('year').toISOString()
    // query.set('startDate', new Date().toISOString())
    query.set('nowDate', moment.utc().toISOString())
    query.set('startYear', startYear)
  }

  const response = await queryService.run<Array<IEvent>>(
    app.environment,
    {
      name: isGlobalEvents
        ? 'get-app-kty-uluslararasi-etkinlikler'
        : isArchivePage
        ? 'kty-arsiv-etkinlikler-get-sorgusu'
        : 'get-app-kty-etkinlikler',
      language: app.language,
      query: query.toString(),
    },
    EVENTS_MAPPING
  )

  // Eğer herhangi bir etkinlik yok ise ve global etkinlikler devam ediyor ise, etkinlik
  // sayfasında global etkinlikleri göstermeye başla.
  if (!isGlobalEvents && !isArchivePage && response?.meta?.totalData === 0) {
    const globalEvets = await queryService.run<Array<IEvent>>(
      app.environment,
      {
        name: 'get-app-kty-uluslararasi-etkinlikler',
        language: app.language,
        query: query.toString(),
      },
      EVENTS_MAPPING
    )

    if (globalEvets?.meta?.totalData > 0) {
      return globalEvets
    }
  }

  return response
}

const LoadMoreButton: React.FC<{ loadMore: () => void }> = ({ loadMore }) => {
  const loadMoreRef = useRef<HTMLButtonElement>(null)
  const isInView = useInView(loadMoreRef, {
    once: true,
    margin: '-200px',
  })
  const app = useApp()

  useEffect(() => {
    if (isInView) {
      loadMore()
    }
  }, [isInView, loadMore])

  return (
    <Row>
      <div className="other-events-buttonv2">
        <Button ref={loadMoreRef} onClick={loadMore}>
          <span>{app.settings.translations['seeMore']}</span>
          <Icon name="arrow-right" size="small" />
        </Button>
      </div>
    </Row>
  )
}

const EventListPage: React.FC<Props> = ({
  page,
  loadMore,
  isLastPage,
  isHomePage,
  festivalID,
  isArchivePage,
  isGlobalEvents,
}) => {
  const app = useApp()
  const router = useRouter()
  const pathname = usePathname()

  const { data, isLoading, error, isValidating } = useSWR(
    `/events/${page}${window.location.search}`,
    () => fetcher(app, page, isArchivePage, isGlobalEvents),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (newData) => {
        mutate(newData, false)
      },
    }
  )

  useEffect(() => {
    const fetchData = async () => {
      const newData = await fetcher(app, page, isArchivePage, isGlobalEvents)
      mutate(`/events/${page}${window.location.search}`, newData, false)
    }
    fetchData()
  }, [app, router, page, isArchivePage])

  if (isLoading || isValidating)
    return (
      <Row>
        {Array(8)
          .fill(null)
          .map((_, index) => (
            <Column
              key={index}
              xs={{ size: 12 }}
              sm={{ size: 6 }}
              md={{ size: 4 }}
              lg={{ size: 3 }}
            >
              <EventCardSkeleton />
            </Column>
          ))}
      </Row>
    )

  if (error) return <div>Error</div>
  if (!data) return <div>No data</div>

  if (page === 1 && data.data.length === 0) {
    return (
      <Row style={{ margin: 'auto' }}>
        <div className="no-data">
          <span className="no-data-text">
            {app.settings.translations['noSearchData']}
          </span>
        </div>
      </Row>
    )
  }

  return (
    <>
      {data.data.map((event, index) => (
        <Column
          key={index}
          xs={{ size: 12 }}
          sm={{ size: 6 }}
          md={{ size: 4 }}
          lg={{ size: 3 }}
        >
          <EventCard {...event} />
        </Column>
      ))}
      {PAGE_SIZE * page < data.meta.totalData && isLastPage && (
        <>
          {isHomePage && page === 3 ? (
            <Row>
              <div className="other-events-buttonv2">
                <Anchor
                  href={
                    app.settings.routes['events'].href + window.location.search
                  }
                >
                  <span>{app.settings.translations['allEvent']}</span>
                  <Icon name="arrow-right" size="small" />
                </Anchor>
              </div>
            </Row>
          ) : (
            <LoadMoreButton loadMore={loadMore} />
          )}
        </>
      )}
    </>
  )
}

export default EventListPage
