import React, { useEffect } from 'react'
import { IEventCardProps, ICategoriesProps } from './types'
import Image from '@/components/base/image/Image'
import { functions, useApp } from '@wap-client/core'
import Icon from '@/components/base/icon'
import Anchor from '@/components/base/anchor/Anchor'

import moment from 'moment'
import 'moment/locale/tr'

const EventCard: React.FC<IEventCardProps> = ({
  id,
  baslik,
  page,
  baslangicTarihi,
  bitisTarihi,
  kategori,
  festival,
  gorsel,
  mekan,
  galeri,
  katilimcilar,
  seanslar,
  ucretsiz,
  globalEvent = false,
}) => {
  const app = useApp()

  useEffect(() => {
    moment.locale(app.language.substring(0, 2))
  }, [app.language])

  const renderConvertDate = () => {
    const startDate = moment.utc(baslangicTarihi).format('DD MMMM YYYY')
    const endDate = moment.utc(bitisTarihi).format('DD MMMM YYYY')

    if (startDate === endDate) {
      return <div className="info-text">{startDate}</div>
    } else if (
      moment.utc(baslangicTarihi).format('MMMM YYYY') ===
      moment.utc(bitisTarihi).format('MMMM YYYY')
    ) {
      return (
        <div className="info-text">{`${moment
          .utc(baslangicTarihi)
          .format('DD')} - ${moment
          .utc(bitisTarihi)
          .format('DD MMMM YYYY')}`}</div>
      )
    } else {
      return <div className="info-text">{`${startDate} - ${endDate}`}</div>
    }
  }

  return (
    <>
      <Anchor href={page?.path}>
        <div className="event-card">
          <div className="event-card-image">
            <Image {...gorsel} alt={baslik} />
          </div>
          <div className="event-card-content">
            <Categories ucretsiz={ucretsiz} kategori={kategori} />
            <h3 className="event-card-content-title" title={baslik}>
              {baslik}
            </h3>
            <div className="event-card-content-infos">
              <div className="info date">
                <Icon name="calendar" size="small" />
                {renderConvertDate()}
              </div>
              <div className="info">
                <Icon name="pin" size="small" />
                <span className="info-text">
                  {mekan?.baslik}
                  {festival?.sehir && ` - ${festival.sehir}`}
                </span>
              </div>
              {globalEvent && festival?.baslik && (
                <div className="info festival">
                  <span>{festival.baslik}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Anchor>
    </>
  )
}

const Categories: React.FC<ICategoriesProps> = ({
  ucretsiz = false,
  kategori,
}) => {
  const app = useApp()
  return (
    <ul className="events-content-labels">
      <li className="labels-item">
        <span>{kategori?.baslik}</span>
      </li>
      <li
        className={functions.classnames(
          'labels-item',
          ucretsiz ? 'free' : 'pain'
        )}
      >
        <span>
          {ucretsiz
            ? app.settings.translations['freeEvent']
            : app.settings.translations['paidEvent']}
        </span>
      </li>
    </ul>
  )
}

export default EventCard
